import React from "react";
import { Modal, Stack, DropZone } from "@shopify/polaris";
import AssetsResourceList from "../AssetsResourceList";

export default function UploadFileModal({ isOpen, onClose, onUpload }) {
  const [files, setFiles] = React.useState([]);

  const items = files.map(file => {
    //FIXME:
    if (file.type) {
      if (file.type.startsWith("image/")) {
        return { type: "image", name: file.name, size: file.size, file };
      }
      return { type: "file", name: file.name, size: file.size, file };
    }
    return { type: "file", name: file.name, size: file.size, file };
  });

  return (
    <div>
      <Modal
        large
        open={isOpen}
        onClose={onClose}
        title="Upload fie"
        primaryAction={{
          content: "Upload",
          onAction: () => {
            setFiles([]);
            onUpload(files);
          },
          disabled: files.length === 0,
        }}
        secondaryActions={[
          {
            content: "Cancel",
            onAction: onClose,
          },
        ]}
      >
        <Modal.Section>
          <Stack vertical>
            <DropZone
              type="file"
              onDrop={newFiles => {
                setFiles(files.concat(newFiles));
              }}
            >
              <DropZone.FileUpload />
            </DropZone>
            <AssetsResourceList
              items={items}
              getItemShortCutActions={item => [
                {
                  content: "Remove",
                  url: "",
                  onAction: () => {
                    const newFiles = files.filter(
                      file => file.name !== item.name
                    );
                    setFiles(newFiles);
                  },
                },
              ]}
            />
          </Stack>
        </Modal.Section>
      </Modal>
    </div>
  );
}
