import React from "react";
import notFound from "./404.svg";
import { EmptyState, Layout } from "@shopify/polaris";

function NotFound() {
  return (
    <Layout>
      <EmptyState
        heading="Questa pagina non esiste"
        action={{ content: "Home", url: "/dashboard" }}
        image={notFound}
      />
    </Layout>
  );
}

export default NotFound;
