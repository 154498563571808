import React, { useState, useEffect } from "react";
import {
  Modal,
  TextContainer,
  Form,
  FormLayout,
  TextField,
  Banner,
} from "@shopify/polaris";

function ModalAddReseller({
  isOpen,
  onClose,
  onChange,
  onCreateReseller,
  error,
}) {
  const [resellerEmail, setResellerEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(false);
  }, [error]);

  async function handleCreateReseller() {
    if (!error) {
      setIsLoading(true);
      onCreateReseller(resellerEmail);
    }
  }
  return (
    <div>
      <Modal
        open={isOpen}
        onClose={onClose}
        title="Add reseller"
        primaryAction={{
          content: "Add",
          onAction: handleCreateReseller,
          disabled: !!!resellerEmail,
          loading: isLoading,
        }}
      >
        <Modal.Section>
          <Form onSubmit={handleCreateReseller}>
            <FormLayout>
              <TextField
                value={resellerEmail}
                onChange={name => {
                  onChange();
                  setResellerEmail(name);
                }}
                label="Email"
                type="email"
                autoFocus
              />
            </FormLayout>
          </Form>
        </Modal.Section>
        {error && (
          <Modal.Section>
            <Banner title={error} status="critical" />
          </Modal.Section>
        )}
      </Modal>
    </div>
  );
}

export default ModalAddReseller;
