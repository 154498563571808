import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Modal, Spinner } from "@shopify/polaris";
import { Storage } from "aws-amplify";
import { DeleteMajorMonotone, ArrowDownMinor } from "@shopify/polaris-icons";

function ModalImage({ isOpen, onClose, image, onRemove, onDownload }) {
  const [url, setUrl] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    async function getUrl() {
      try {
        const { bucket, region, key } = image.file;
        const [, , keyWithoutPrefix] = /([^/]+\/){2}(.*)$/.exec(key) || key;

        const url: string = await Storage.get(keyWithoutPrefix, {
          bucket,
          region,
          level: "protected",
          identityId: image.owner,
        });
        setUrl(url);
      } catch (err) {
        console.log(err);
        Sentry.captureException(err);
      } finally {
        setIsLoading(false);
      }
    }
    getUrl();
  }, []);

  //FIXME: extract download image and call from here
  const remove = {
    content: "Remove",
    onAction: () => {
      setIsDeleting(true);
      onRemove(image);
    },
    destructive: true,
    loading: isDeleting,
    icon: DeleteMajorMonotone,
  };
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      primaryAction={onRemove ? remove : null}
      secondaryActions={[
        {
          content: "Download",
          onAction: () => onDownload(image),
          icon: ArrowDownMinor,
        },
      ]}
      large
    >
      <Modal.Section>
        <ImageContainer>
          {isLoading ? (
            <Spinner size="large" color="teal" />
          ) : (
            <Img src={url} />
          )}
        </ImageContainer>
      </Modal.Section>
    </Modal>
  );
}

const ImageContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const Img = styled.img`
  padding: 10px;
  max-width: 100%;
`;

export default ModalImage;
