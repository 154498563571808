import React, { useState, useEffect } from "react";
import { Storage } from "aws-amplify";
import { Thumbnail, SkeletonThumbnail } from "@shopify/polaris";

function S3Thumbnail({ file }) {
  const [url, setUrl] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function getUrl() {
      try {
        const { bucket, region, key } = file.file;
        const [, , keyWithoutPrefix] = /([^/]+\/){2}(.*)$/.exec(key) || key;

        const url: string = await Storage.get(keyWithoutPrefix, {
          bucket,
          region,
          level: "protected",
          identityId: file.owner,
        });

        setUrl(url);
      } catch (err) {
        console.log(err);
        Sentry.captureException(err);
      } finally {
        setIsLoading(false);
      }
    }
    getUrl();
  }, []);
  return (
    <>
      {isLoading ? (
        <SkeletonThumbnail size="medium" />
      ) : (
        <Thumbnail source={url} alt={file.name} />
      )}
    </>
  );
}

export default S3Thumbnail;
