import React from "react";
import {
  Layout,
  Card,
  SkeletonBodyText,
  SkeletonPage,
  ResourceList,
  SkeletonThumbnail,
} from "@shopify/polaris";

function LoadingPage() {
  return (
    <SkeletonPage secondaryActions={2}>
      <Layout>
        <Layout.Section>
          <Card sectioned>
            <ResourceList
              items={[
                {
                  id: 1,
                },
                {
                  id: 2,
                },
                {
                  id: 3,
                },
              ]}
              renderItem={item => {
                const { id } = item;

                return (
                  <ResourceList.Item
                    id={id}
                    media={<SkeletonThumbnail size="medium" />}
                  >
                    <SkeletonBodyText />
                  </ResourceList.Item>
                );
              }}
            />
          </Card>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  );
}

export default LoadingPage;
