import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { API, graphqlOperation } from "aws-amplify";
import { getFolderByStatusAndParentId } from "graphql/queries";
import { createFolder, updateFolder } from "graphql/mutations";
import { ListFoldersQuery } from "API";
import ModalImage from "./modals/ModalImage";
import ReactGA from "react-ga";
import { Logger } from "aws-amplify";

import {
  Card,
  Layout,
  Page,
  Loading,
  EmptyState,
  Button,
  Spinner,
  ChoiceList,
  Select,
  Toast,
} from "@shopify/polaris";
import {
  FolderPlusMajorMonotone,
  PagePlusMajorMonotone,
} from "@shopify/polaris-icons";

import ModalCreateFolder from "./modals/ModalCreateFolder";
import { Storage } from "aws-amplify";
import ModalUploadFile from "./modals/ModalUploadFile";
import LoadingPage from "./LoadingPage";
import { useAppSync } from "../hooks/useAppSync";
import AssetsResourceList from "./AssetsResourceList";
import { deleteFile } from "../graphql/mutations";
import { useUser } from "../hooks/useUser";
// import emptyFolder from "./empty-folder.svg";
import emptyState from "./empty-state.svg";
import NotFound from "./404";
import Helmet from "react-helmet";
import ErrorFetch from "./errors/ErrorFetch";

interface IFolder {
  id: string;
  name: string;
  parentID: string;
}

interface IFile {
  id: string;
  name: string;
  file: {
    key: string;
    bucket: string;
    region: string;
  };
}

type IFileOrFolder = IFolder | IFile;

const paginationLimit = 20;
const getFolderWithFiles = /* GraphQL */ `
  query GetFolder(
    $id: ID!
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    getFolder(id: $id) {
      id
      name
      status
      parentID
      files(
        limit: $limit
        nextToken: $nextToken
        sortDirection: $sortDirection
      ) {
        items {
          id
          name
          owner
          size
          file {
            bucket
            region
            key
          }
        }
        nextToken
      }
    }
  }
`;

interface IProps {
  folderId: string;
}
function ListFiles({ folderId }: IProps) {
  const logger = new Logger("Files");

  const [files, setFiles] = useState<Array<IFile>>([]);
  const [folders, setFolders] = useState<Array<IFolder>>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isModalUploadFileOpen, setIsModalUploadFileOpen] = useState(false);
  const [isModalCreateFolderOpen, setIsModalCreateFolderOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(undefined);
  const [folderName, setFolderName] = useState("");
  const [folderParent, setFolderParent] = useState("");
  const [showSkeleton, setShowSkeleton] = useState(true);
  const { uploadFiles, uploadStatus } = useAppSync();
  const [isModifingList, setIsModifingList] = useState(false);
  const { user } = useUser();
  const [nextTokenForFiles, setNextTokenForFiles] = useState(undefined);
  const [nextTokenForFolders, setNextTokenForFolders] = useState(undefined);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [selectedSorting, setSelectedSorting] = useState("DESC");
  const [isCurrentFolderDeleted, setIsCurrentFolderDeleted] = useState(false);
  const [error, setError] = useState("");
  const [isErrorFetching, setIsErrorFetching] = useState(false);
  const [folderNotExisting, setFolderNotExisting] = useState(false);

  useEffect(() => {
    async function fetchFoldersAndFiles() {
      const folders = await getFolders();

      if (folders) {
        setFolders(folders);
      }
      if (folderId && folders.length < paginationLimit) {
        const files = await fetchFolderContent({
          limit: paginationLimit - folders.length,
          nextToken: null,
        });
        if (files) {
          setFiles(files);
        }
      } else {
        setFiles([]);
        setNextTokenForFiles(undefined);
      }
      setShowSkeleton(false);
    }
    setShowSkeleton(true);
    setIsCurrentFolderDeleted(false);
    setIsErrorFetching(false);
    setFolderNotExisting(false);
    fetchFoldersAndFiles();
  }, [folderId]);

  async function handleLoadMore() {
    setIsLoadingMore(true);
    let foldersToAdd = [];
    let filesToAdd = [];
    if (nextTokenForFolders) {
      foldersToAdd = await getFolders();
    }
    if (nextTokenForFiles) {
      filesToAdd = await fetchFolderContent({
        limit: paginationLimit - filesToAdd.length,
        nextToken: nextTokenForFiles,
      });
    }
    setFolders([...folders, ...foldersToAdd]);
    setFiles([...files, ...filesToAdd]);
    setIsLoadingMore(false);
  }

  async function getFolders(): Promise<Array<IFolder>> {
    console.log("getFolders", {
      folderId,
      paginationLimit,
      nextTokenForFolders,
    });
    try {
      const { data }: ListFoldersQuery = await API.graphql(
        graphqlOperation(getFolderByStatusAndParentId, {
          status: "ACTIVE",
          parentID: {
            eq: folderId || "NONE",
          },
          limit: paginationLimit,
          nextToken: nextTokenForFolders ? nextTokenForFolders : null,
        })
      );
      console.log("data", data);
      console.log(data.getFolderByStatusAndParentId);
      setNextTokenForFolders(data.getFolderByStatusAndParentId.nextToken);
      return data.getFolderByStatusAndParentId.items.map(item => ({
        ...item,
        type: "folder",
      }));
    } catch (err) {
      logger.error(err);
      console.log(err);
      setIsErrorFetching(true);
    }
  }

  async function fetchFolderContent({
    limit = paginationLimit,
    sort = selectedSorting,
    nextToken = nextTokenForFiles,
  }): Promise<Array<IFile>> {
    console.log("fetchFolderContent", { limit, sort, nextToken });
    try {
      const { data } = await API.graphql(
        graphqlOperation(getFolderWithFiles, {
          id: folderId,
          limit,
          nextToken,
          sortDirection: sort,
        })
      );
      console.log("DATA fetchFOlderContent", data);
      if (!data.getFolder) {
        setFolderNotExisting(true);
        return [];
      }
      if (data.getFolder.status === "DELETED") {
        setIsCurrentFolderDeleted(true);
      }
      //FIXME:
      setFolderName(data.getFolder.name);
      setFolderParent(data.getFolder.parentID);
      setNextTokenForFiles(data.getFolder.files.nextToken);
      return data.getFolder.files.items.map(item => {
        const imagesFormat = ["jpg", "jpeg", "png", "gif", "svg"];
        const nameSplitted = item.name.split(".");
        if (imagesFormat.includes(nameSplitted[nameSplitted.length - 1])) {
          return { ...item, type: "image" };
        }
        return { ...item, type: "file" };
      });
    } catch (err) {
      console.log(err);
      logger.error(err);
      setIsErrorFetching(true);
    }
  }

  async function handleCreateFolder(folderName) {
    const folderDetails = {
      name: folderName,
      parentID: folderId ? folderId : "NONE",
      createdAt: Math.floor(Date.now() / 1000),
      status: "ACTIVE",
    };
    try {
      setIsLoading(true);
      const { data } = await API.graphql(
        graphqlOperation(createFolder, { input: folderDetails })
      );
      const newFolder: IFolder = {
        id: data.createFolder.id,
        name: data.createFolder.name,
        parentID: data.createFolder.parentID,
        type: "folder",
      };
      setFolders([...folders, newFolder]);
      setIsModalCreateFolderOpen(false);
    } catch (err) {
      console.log(err);
      setError("There was an error");
    } finally {
      setIsLoading(false);
      setIsModalCreateFolderOpen(false);
    }
  }
  function handleUpload(files) {
    setIsModalUploadFileOpen(false);
    uploadFiles({ folderId, files, onUploadedFile: handleUploadedFile });
  }

  async function handleDeleteFile(file) {
    console.log("DELETE file", file);
    if (!user.isAdmin) {
      return;
    }
    try {
      setIsModifingList(true);
      const { data } = await API.graphql(
        graphqlOperation(deleteFile, { input: { id: file.id } })
      );
      console.log("DELETE RESP", data);
      let newFile = [];
      if (nextTokenForFiles) {
        newFile = await fetchFolderContent({ limit: 1 });
      }
      console.log("newFile", newFile);
      setIsModifingList(false);
      setSelectedImage(undefined);

      setFiles([...files.filter(f => f.id !== data.deleteFile.id), ...newFile]);
    } catch (err) {
      console.log(err);
      logger.error(err);
      setIsModifingList(false);
      setError("There was an error");
    }
  }
  async function handleUploadedFile({
    filesUploaded,
    folderId: folderIdOfUploadedFiles,
  }) {
    if (selectedSorting === "DESC") {
      setFiles([...filesUploaded.reverse(), ...files]);
    } else {
      if (!nextTokenForFiles) {
        setFiles([...files, ...filesUploaded]);
      }
    }
  }

  //FIXME: use shopify download button
  //FIXME: extract logic!
  async function handleDownloadFile(item) {
    const { bucket, region, key } = item.file;
    console.log("handle download file", item.file);
    logger.info("handle download file", item.file);
    const { name, owner } = item;
    const [, , keyWithoutPrefix] = /([^/]+\/){2}(.*)$/.exec(key) || key;

    try {
      const url: string = await Storage.get(keyWithoutPrefix, {
        bucket,
        region,
        level: "protected",
        identityId: owner,
      });
      //FIXME: await
      function download(url, filename) {
        setIsLoading(true);
        fetch(url)
          .then(function(t) {
            return t.blob().then(b => {
              let a = document.createElement("a");
              document.body.appendChild(a);
              a.href = URL.createObjectURL(b);
              a.setAttribute("download", filename);
              a.click();
              setIsLoading(false);
              if (!user.isAdmin) {
                const emailForAnalytics = user.email.split("@").join("[at]");
                console.log("Send event", {
                  fileName: item.name,
                  fileId: item.id,
                  email: user.email,
                });
                ReactGA.event({
                  category: "Download file",
                  action: `${folderName}/${item.name}`,
                  label: emailForAnalytics,
                });
              }
            });
          })
          .catch(err => {
            logger.error(err);
            setError("There was an error");
          });
      }
      download(url, name);
    } catch (err) {
      setError("There was an error");
    }
  }

  async function handleChangeSort(value) {
    setSelectedSorting(value);
    if (folderId && folders.length < paginationLimit) {
      setIsModifingList(true);
      try {
        const files = await fetchFolderContent({
          limit: paginationLimit - folders.length,
          nextToken: null,
          sort: value,
        });
        if (files) {
          setFiles(files);
        }
      } catch (err) {
        console.log(err);
      } finally {
        setIsModifingList(false);
      }
    } else {
      setFiles([]);
      setNextTokenForFiles(undefined);
    }
  }

  async function handleDeleteFolder(folder) {
    try {
      setIsModifingList(true);
      const { data } = await API.graphql(
        graphqlOperation(updateFolder, {
          input: { id: folder.id, status: "DELETED" },
        })
      );

      setFolders(folders.filter(f => f.id !== folder.id));
    } catch (err) {
      console.log(err);
      setError("There was an error");
    } finally {
      setIsModifingList(false);
    }
  }

  const createFolderAction = {
    content: "Create folder",
    icon: FolderPlusMajorMonotone,
    onAction: () => setIsModalCreateFolderOpen(true),
  };
  const uploadFileAction = {
    content: "Upload file",
    icon: PagePlusMajorMonotone,
    onAction: () => setIsModalUploadFileOpen(true),
  };

  let actions = [];
  if (user.isAdmin) {
    actions = !folderId
      ? [createFolderAction]
      : [createFolderAction, uploadFileAction];
  }

  let itemShortCutActions = item => {
    const downloadFileAction = {
      content: "Download",
      url: "",
      onAction: () => {
        handleDownloadFile(item);
      },
    };

    const removeFileAction = {
      content: "Remove",
      url: "",
      onAction: () => {
        handleDeleteFile(item);
      },
    };

    const removeFolderAction = {
      content: "Remove",
      url: "",
      onAction: () => {
        handleDeleteFolder(item);
      },
    };
    if (item.type === "folder" && user.isAdmin) {
      return [removeFolderAction];
    }
    if ((item.type === "image" || item.type === "file") && user.isAdmin) {
      return [removeFileAction, downloadFileAction];
    }
    if (item.type === "folder" && !user.isAdmin) {
      return [];
    }
    return [downloadFileAction];
  };
  if (isCurrentFolderDeleted) {
    return <NotFound />;
  }

  if (isErrorFetching) {
    return (
      <Page title="File">
        <ErrorFetch />
      </Page>
    );
  }

  if (folderNotExisting) {
    return (
      <Page title="File">
        <NotFound />
      </Page>
    );
  }
  // console.log("nextTokenForFolders", nextTokenForFolders);
  // console.log("nextTokenForFiles", nextTokenForFiles);
  return (
    <CustomClassContainer>
      {isDownloading && <Loading />}
      {showSkeleton ? (
        <LoadingPage />
      ) : (
        <Page
          title={folderId ? folderName : "File"}
          breadcrumbs={
            folderId
              ? [
                  {
                    content: "Back",
                    url:
                      folderParent !== "NONE"
                        ? `/dashboard/folders/${folderParent}`
                        : "/dashboard",
                  },
                ]
              : []
          }
          secondaryActions={actions}
        >
          <Layout>
            {!!folderId ? (
              <Helmet>
                <title>{folderName} | Reserved area</title>
              </Helmet>
            ) : (
              <Helmet>
                <title> Reserved area</title>
              </Helmet>
            )}

            <Layout.Section>
              {isLoading && <Loading />}
              {!!folders.length || files.length ? (
                <Card>
                  <AssetsResourceList
                    loading={isModifingList}
                    items={[...folders, ...files]}
                    getItemShortCutActions={itemShortCutActions}
                    onClick={item => setSelectedImage(item)}
                    sortOptions={
                      folderId
                        ? [
                            { label: "Oldest first", value: "ASC" },
                            { label: "Latest first", value: "DESC" },
                          ]
                        : null
                    }
                    onSortChange={handleChangeSort}
                    sortValue={selectedSorting}
                  />
                  {(!!nextTokenForFolders || !!nextTokenForFiles) && (
                    <LoadMoreContainer>
                      {isLoadingMore ? (
                        <Spinner />
                      ) : (
                        <Button onClick={handleLoadMore}>Load more</Button>
                      )}
                    </LoadMoreContainer>
                  )}
                </Card>
              ) : (
                <EmptyState
                  heading={
                    folderId ? "La cartella è vuota" : "Non ci sono cartelle"
                  }
                  action={{ content: "Home", url: "/dashboard" }}
                  image={emptyState}
                />
              )}

              <ModalUploadFile
                isOpen={isModalUploadFileOpen}
                onUpload={handleUpload}
                onClose={() => setIsModalUploadFileOpen(false)}
              />
              <ModalCreateFolder
                isOpen={isModalCreateFolderOpen}
                folderId={folderId}
                onCreateFolder={handleCreateFolder}
                onClose={() => setIsModalCreateFolderOpen(false)}
              />
              {!!selectedImage && (
                <ModalImage
                  onRemove={user.isAdmin ? handleDeleteFile : undefined}
                  onDownload={handleDownloadFile}
                  isOpen={true}
                  image={selectedImage}
                  onClose={() => setSelectedImage(undefined)}
                />
              )}
            </Layout.Section>
          </Layout>
          {error && (
            <Toast content={error} error onDismiss={() => setError("")} />
          )}
        </Page>
      )}
    </CustomClassContainer>
  );
}

const LoadMoreContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;

const CustomClassContainer = styled.div`
  .Polaris-EmptyState__Actions {
    display: none;
  }
`;
export default ListFiles;
