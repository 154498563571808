export const MapEntries = [
  ["User does not exist", /user.*not.*exist/i],
  ["User already exists", /user.*already.*exist/i],
  ["Incorrect username or password", /incorrect.*username.*password/i],
  ["Password not valid", /validation.*password/i],
];

const parseErrorMessage = message => {
  const match = MapEntries.filter(entry => entry[1].test(message));
  if (match.length === 0) {
    return message;
  }
  const entry = match[0];
  const msg = entry.length > 2 ? entry[2] : entry[0];
  return msg;
};

// const parseLambdaError = message => {
//   const match = MapEntries.filter(entry => entry[1].test(message));
//   if (match.length === 0) {
//     return message;
//   }
//   const entry = match[0];
//   const msg = entry.length > 2 ? entry[2] : entry[0];
//   return msg;
// };

export const parseAmplifyError = err => {
  switch (err.code) {
    case "UserNotFoundException":
      return "The user does not exist";
    case "LimitExceededException":
      return "Too many request, retry later";
    case "InvalidParameterException":
      return parseErrorMessage(err.message);
    case "UsernameExistsException":
      return "User already exists";
    case "InvalidPasswordException":
      return "Password not valid";
    case "CodeMismatchException":
      return "The code is not valid";
    default:
      return "There was an error";
  }
};

export const parseUserCreationError = err => {
  if (err.includes("UsernameExistsException")) {
    return "A user with this email already exists";
  }
  if (err.includes("InvalidParameterException")) {
    return "This mail is not valid";
  }
  return "There was an error, contatta gli amministratori";
};
