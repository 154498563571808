interface IProps {
  totalFiles: number;
  filesAlreadyUploaded: number;
}

import React from "react";
import { Card, Banner, ProgressBar, TextContainer } from "@shopify/polaris";
import styled from "styled-components";
import { ArrowUpMinor } from "@shopify/polaris-icons";

export default function FileUploadStatusBanner({
  totalFiles,
  filesAlreadyUploaded,
}: IProps) {
  return (
    <Container>
      <Banner
        title={`Caricamento file (${filesAlreadyUploaded} di ${totalFiles})`}
        status="info"
        icon={ArrowUpMinor}
      >
        <TextContainer>
          <p>Non aggiornare la pagina finchè l'upload non è completato.</p>
          <ProgressBarWrapper>
            <ProgressBar
              progress={(filesAlreadyUploaded / totalFiles) * 100}
              size="small"
            />
          </ProgressBarWrapper>
        </TextContainer>
      </Banner>
    </Container>
  );
}

const Container = styled.div`
  .Polaris-Banner > :last-child {
    width: 100%;
  }
`;

const ProgressBarWrapper = styled.div`
  padding-right: 40px;
`;
