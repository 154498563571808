import React, { useState } from "react";
import {
  Modal,
  TextContainer,
  Form,
  FormLayout,
  TextField,
} from "@shopify/polaris";

function ModalCreateFolder({ isOpen, onClose, onCreateFolder }) {
  const [folderName, setFolderName] = useState("");

  async function handleCreateFolder() {
    if (!folderName) {
      return;
    }
    onCreateFolder(folderName);
    setFolderName("");
  }
  return (
    <div>
      <Modal
        open={isOpen}
        onClose={onClose}
        title="Create folder"
        primaryAction={{
          content: "Create",
          onAction: handleCreateFolder,
          disabled: !!!folderName,
        }}
      >
        <Modal.Section>
          <Form onSubmit={handleCreateFolder}>
            <FormLayout>
              <TextField
                value={folderName}
                onChange={name => setFolderName(name)}
                label="Folder name"
                type="text"
                autoFocus
              />
            </FormLayout>
          </Form>
        </Modal.Section>
      </Modal>
    </div>
  );
}

export default ModalCreateFolder;
