import React from "react";
import styled from "styled-components";
import { Layout, EmptyState } from "@shopify/polaris";
import fetchError from "components/fetch-error.svg";

function ErrorFetch() {
  return (
    <Layout>
      <Layout.Section>
        <CustomClassContainer>
          <EmptyState
            heading="C'è stato un problema durante il download dei dati"
            action={{ content: "Home", url: "/dashboard" }}
            image={fetchError}
          />
        </CustomClassContainer>
      </Layout.Section>
    </Layout>
  );
}

const CustomClassContainer = styled.div`
  .Polaris-EmptyState__Actions {
    display: none;
  }
`;

export default ErrorFetch;
