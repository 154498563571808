import React, { useState } from "react";
import styled from "styled-components";
import {
  ResourceList,
  TextStyle,
  Thumbnail,
  Icon,
  SkeletonThumbnail,
  Button,
  Avatar,
} from "@shopify/polaris";
import { FolderMajorMonotone, NoteMajorMonotone } from "@shopify/polaris-icons";
import S3Thumbnail from "./S3Thumbnail";
import { formatBytes } from "../utils/math";

// type Item = {

// }
// interface ResourceList {
//   items: [Item],
//   getItemShortCutActions,
//   loading,
//   onClick,
//   sortValue,
//   sortOptions,
//   onSortChange,
// }

export default function AssetsResourceList({
  items,
  getItemShortCutActions,
  loading,
  onClick,
  sortValue,
  sortOptions,
  onSortChange,
  onSearch,
  isSearching,
  searchable,
  type = "file",
}) {
  const [searchString, setSearchString] = useState("");
  const [isShowfilter, setisShowFiler] = useState();
  const [filterString, setFilerString] = useState();

  function handleFiltersChange(appliedFilters) {
    console.log(appliedFilters);
    setSearchString("");
    setisShowFiler(false);
    onSearch("");
  }

  const filterControl = (
    <ResourceList.FilterControl
      filters={[]}
      appliedFilters={
        isShowfilter
          ? [
              {
                key: "searchQuery",
                value: filterString,
              },
            ]
          : []
      }
      onFiltersChange={handleFiltersChange}
      searchValue={searchString}
      onSearchChange={value => setSearchString(value)}
      additionalAction={{
        content: "Search",
        loading: isSearching,
        onAction: () => {
          if (searchString) {
            setisShowFiler(true);
            setFilerString(searchString);
            onSearch(searchString);
          }
        },
      }}
    />
  );
  console.log(items);
  return (
    <ResourceList
      resourceName={{
        singular: type === "file" ? "User" : "File",
        plural: type === "file" ? "Files" : "Users",
      }}
      items={items}
      loading={loading}
      sortValue={sortValue}
      sortOptions={sortOptions}
      onSortChange={onSortChange}
      filterControl={searchable ? filterControl : null}
      renderItem={item => {
        const { type } = item;
        switch (type) {
          case "folder":
            return (
              <ResourceList.Item
                id={item.id}
                url={`/dashboard/folders/${item.id}`}
                media={
                  <IconWrapper>
                    <Icon source={FolderMajorMonotone} color="inkLighter" />
                  </IconWrapper>
                }
                shortcutActions={getItemShortCutActions(item)}
                accessibilityLabel={`View ${item.name}`}
                persistActions
              >
                <h3>
                  <TextStyle variation="strong">{item.name}</TextStyle>
                </h3>
              </ResourceList.Item>
            );
          case "file":
            return (
              <ResourceList.Item
                id={item.id}
                media={
                  <IconWrapper>
                    <Icon source={NoteMajorMonotone} color="inkLighter" />
                  </IconWrapper>
                }
                accessibilityLabel={`View ${item.name}`}
                shortcutActions={getItemShortCutActions(item)}
                persistActions
              >
                <h3>
                  <TextStyle variation="strong">{item.name}</TextStyle>
                  <div>{formatBytes(item.size)}</div>
                </h3>
              </ResourceList.Item>
            );
          case "image":
            return (
              <ImageAsset
                item={item}
                getItemShortCutActions={getItemShortCutActions}
                onClick={onClick}
              />
            );
          case "user":
            const email = item.Attributes.find(a => a.Name === "email").Value;
            return (
              <ResourceList.Item
                id={item.username}
                media={
                  <Avatar
                    customer
                    size="medium"
                    name={email}
                    initials={email[0]}
                  />
                }
                accessibilityLabel={`View ${email}`}
                shortcutActions={getItemShortCutActions(item)}
                persistActions
              >
                <h3>
                  <TextStyle variation="strong">{email}</TextStyle>
                </h3>
              </ResourceList.Item>
            );
          default:
            throw new Error("Unexpected asset type");
        }
      }}
    />
  );
}

function ImageAsset({ item, getItemShortCutActions, onClick }) {
  const media = item.file.bucket ? (
    <IconWrapper>
      <S3Thumbnail file={item} />
    </IconWrapper>
  ) : (
    <Thumbnail source={window.URL.createObjectURL(item.file)} alt={item.name} />
  );
  return (
    <ResourceList.Item
      id={item.id}
      media={media}
      accessibilityLabel={`View ${item.name}`}
      shortcutActions={getItemShortCutActions(item)}
      persistActions
      onClick={() => onClick(item)}
    >
      <h3>
        <TextStyle variation="strong">{item.name}</TextStyle>
        <div>{formatBytes(item.size)}</div>
      </h3>
    </ResourceList.Item>
  );
}

const IconWrapper = styled.div`
  width: 60px;
  height: 60px;
  display: flex;
`;
