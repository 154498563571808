import React, { useState, useEffect } from "react";
import {
  Frame,
  Navigation,
  TopBar,
  Icon,
  Toast,
  Banner,
  TextContainer,
} from "@shopify/polaris";
import { Router, navigate } from "@reach/router";
import ListFiles from "./ListFiles";
import { useUser } from "hooks/useUser";
import {
  ImagesMajorMonotone,
  CustomersMajorMonotone,
  ArrowUpMinor,
} from "@shopify/polaris-icons";
import ListUsers from "./ListUsers";
import NotFound from "./404";
import { useAppSync } from "../hooks/useAppSync";
import FileUploadStatusBanner from "./FileUploadStatusBanner";

function Dashboard() {
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const { user, logout } = useUser();
  const [showMobileNavigation, setShowMobileNavigation] = useState(false);
  const { uploadStatus, uploadErrors } = useAppSync();

  if (!user) {
    navigate("/");
    return null;
  }

  function onSignOut() {
    navigate("/");
  }
  const userMenuActions = [
    {
      items: [
        {
          content: "Logout",
          onAction: () => {
            logout(onSignOut);
          },
        },
      ],
    },
  ];

  const navigationUserMenuMarkup = (
    <Navigation.UserMenu
      actions={userMenuActions}
      name={user.email}
      avatarInitials={user.email[0].toUpperCase()}
    />
  );

  const files = {
    label: "Files",
    url: "/dashboard",
    icon: <Icon source={ImagesMajorMonotone} />,
    onClick: () => {
      setShowMobileNavigation(false);
    },
  };
  const users = {
    label: "Resellers",
    url: "/dashboard/users",
    icon: <Icon source={CustomersMajorMonotone} />,
    onClick: () => {
      setShowMobileNavigation(false);
    },
  };

  const singleUploadStatus = uploadStatus.reduce(
    (acc, singleUpload) => ({
      totalFiles: acc.totalFiles + singleUpload.totalFiles,
      filesUploaded: acc.filesUploaded + singleUpload.filesUploaded,
    }),
    { totalFiles: 0, filesUploaded: 0 }
  );

  const navigationMarkup = (
    <Navigation location="/" userMenu={navigationUserMenuMarkup}>
      <Navigation.Section separator title="Dashboard" items={[files, users]} />
      {!!singleUploadStatus.totalFiles && (
        <FileUploadStatusBanner
          totalFiles={singleUploadStatus.totalFiles}
          filesAlreadyUploaded={singleUploadStatus.filesUploaded}
        />
      )}
    </Navigation>
  );

  const userMenuMarkup = (
    <TopBar.UserMenu
      actions={userMenuActions}
      name={user.email}
      initials={user.email[0].toUpperCase()}
      open={isUserMenuOpen}
      onToggle={() => setIsUserMenuOpen(!isUserMenuOpen)}
    />
  );

  const topBarMarkup = (
    <TopBar
      showNavigationToggle={user.isAdmin}
      userMenu={userMenuMarkup}
      onNavigationToggle={() => setShowMobileNavigation(!showMobileNavigation)}
    />
  );
  return (
    <Frame
      topBar={topBarMarkup}
      navigation={user.isAdmin ? navigationMarkup : null}
      showMobileNavigation={showMobileNavigation}
      onNavigationDismiss={() => setShowMobileNavigation(!showMobileNavigation)}
    >
      <div>
        <Router>
          <ListFiles path="/dashboard" />
          <ListFiles path="/dashboard/folders/:folderId" />
          <ListUsers path="/dashboard/users" />
          <NotFound default />
        </Router>
        {uploadErrors.map(error => (
          <Toast
            key={error.id}
            content={`Non è stato possibile caricare ${error.fileName}`}
            error
            onDismiss={() => error.dismiss(error.id)}
          />
        ))}
      </div>
    </Frame>
  );
}

export default Dashboard;
