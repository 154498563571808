// tslint:disable
// this is an auto generated file. This will be overwritten

export const listUsersInGroup = `query ListUsersInGroup($group: String!, $limit: Int, $nextToken: String) {
  listUsersInGroup(group: $group, limit: $limit, nextToken: $nextToken) {
    users {
      Username
      Attributes {
        Name
        Value
      }
      UserCreateDate
      UserLastModifiedDate
      Enabled
      UserStatus
    }
    nextToken
  }
}
`;
export const searchUsers = `query SearchUsers($limit: Int, $nextToken: String, $searchString: String) {
  searchUsers(
    limit: $limit
    nextToken: $nextToken
    searchString: $searchString
  ) {
    users {
      Username
      Attributes {
        Name
        Value
      }
      UserCreateDate
      UserLastModifiedDate
      Enabled
      UserStatus
    }
    nextToken
  }
}
`;
export const getFolder = `query GetFolder($id: ID!) {
  getFolder(id: $id) {
    id
    name
    description
    files {
      items {
        id
        owner
        name
        size
        createdAt
      }
      nextToken
    }
    parentID
    status
    createdAt
  }
}
`;
export const listFolders = `query ListFolders(
  $filter: ModelFolderFilterInput
  $limit: Int
  $nextToken: String
) {
  listFolders(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      description
      files {
        nextToken
      }
      parentID
      status
      createdAt
    }
    nextToken
  }
}
`;
export const getFile = `query GetFile($id: ID!) {
  getFile(id: $id) {
    id
    owner
    name
    file {
      bucket
      region
      key
    }
    size
    createdAt
    folder {
      id
      name
      description
      files {
        nextToken
      }
      parentID
      status
      createdAt
    }
  }
}
`;
export const listFiles = `query ListFiles(
  $filter: ModelFileFilterInput
  $limit: Int
  $nextToken: String
) {
  listFiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      owner
      name
      file {
        bucket
        region
        key
      }
      size
      createdAt
      folder {
        id
        name
        description
        parentID
        status
        createdAt
      }
    }
    nextToken
  }
}
`;
export const getFolderByStatusAndParentId = `query GetFolderByStatusAndParentId(
  $status: FolderStatus
  $parentID: ModelIDKeyConditionInput
  $filter: ModelFolderFilterInput
  $limit: Int
  $nextToken: String
) {
  getFolderByStatusAndParentId(
    status: $status
    parentID: $parentID
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      description
      files {
        nextToken
      }
      parentID
      status
      createdAt
    }
    nextToken
  }
}
`;
