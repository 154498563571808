import React, { useEffect, useState } from "react";
import { listUsersInGroup } from "graphql/queries";
import { createUser, deleteUser } from "graphql/mutations";
import styled from "styled-components";
import { API, graphqlOperation } from "aws-amplify";
import {
  Page,
  EmptyState,
  Card,
  Layout,
  Spinner,
  Button,
  Toast,
} from "@shopify/polaris";
import AssetsResourceList from "./AssetsResourceList";
import { CustomerPlusMajorMonotone } from "@shopify/polaris-icons";
import ModalAddReseller from "./modals/ModalAddReseller";
import LoadingPage from "./LoadingPage";
import { parseUserCreationError } from "../utils/errors";
import emptyCostumer from "./empty-costumer.svg";
import { ListUsersInGroupQuery } from "../API";
import Helmet from "react-helmet";
import ErrorFetch from "./errors/ErrorFetch";
import { searchUsers } from "../graphql/queries";
import { Logger } from "aws-amplify";

function ListUsers() {
  const logger = new Logger("Users");

  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState();
  const [isModalCreateResellerOpen, setIsModalCreateResellerOpen] = useState(
    false
  );
  const [isUpdatingUserList, setIsUpdatingUserList] = useState();
  const [error, setError] = useState();
  const [showSkeleton, setShowSkeleton] = useState(true);
  const [nextToken, setNextToken] = useState();
  const [isLoadingMore, setIsLoadingMore] = useState();
  const [errorAction, setErrorAction] = useState("");
  const [fetchError, setFetchError] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [isFiltered, setIsFilterd] = useState(false);

  const paginationLimit = 20;

  async function fetchUsers(searchString = "", next = nextToken) {
    try {
      const { data } = await API.graphql(
        graphqlOperation(searchUsers, {
          limit: paginationLimit,
          nextToken: next ? next : undefined,
          searchString,
        })
      );

      setNextToken(data.searchUsers.nextToken);
      return data.searchUsers.users.map(u => ({ ...u, type: "user" }));
    } catch (err) {
      console.log(err);
      Sentry.captureException(err);
      setFetchError(true);
      return [];
    } finally {
      setShowSkeleton(false);
    }
  }
  useEffect(() => {
    async function init() {
      const usersFetched = await fetchUsers();
      setUsers(usersFetched);
    }
    setIsFilterd(false);
    init();
  }, []);

  async function handleLoadMore() {
    setIsLoadingMore(true);
    const usersFetched = await fetchUsers();

    setUsers([...users, ...usersFetched]);

    setIsLoadingMore(false);
  }

  async function handleCreateReseller(email) {
    try {
      const { data } = await API.graphql(
        graphqlOperation(createUser, { email: email.toLowerCase() })
      );
      setIsModalCreateResellerOpen(false);
      setError("");
      if (!nextToken) {
        setUsers([...users, { ...data.createUser, type: "user" }]);
      }
    } catch (err) {
      console.log(err);
      const errorMessage = parseUserCreationError(err.errors[0].message);
      setError(errorMessage);
      Sentry.captureException(err);
    }
  }
  async function handleRemoveReseller(item) {
    try {
      setIsUpdatingUserList(true);
      await API.graphql(
        graphqlOperation(deleteUser, { userName: item.Username })
      );
      setUsers(users.filter(u => u.Username !== item.Username));
    } catch (err) {
      setErrorAction("There was an error");
      Sentry.captureException(err);
    } finally {
      setIsUpdatingUserList(false);
    }
  }

  const addSindleUserAction = {
    content: "Add reseller",
    icon: CustomerPlusMajorMonotone,
    onAction: () => setIsModalCreateResellerOpen(true),
  };
  const actions = [addSindleUserAction];

  let itemShortCutActions = item => {
    const removeAction = {
      content: "Remove",
      url: "",
      onAction: () => {
        handleRemoveReseller(item);
      },
    };
    return [removeAction];
  };
  if (fetchError) {
    return (
      <Page title="Resellers">
        <ErrorFetch />
      </Page>
    );
  }
  if (showSkeleton) {
    return <LoadingPage />;
  }

  async function handleSearch(searchString) {
    console.log("searchString", searchString);
    setIsSearching(true);
    setIsFilterd(true);
    setIsUpdatingUserList(true);
    setNextToken(null);
    try {
      const users = await fetchUsers(searchString, "");
      setUsers(users);
    } catch (err) {
      console.log(err);
      logger.error(err);
      Sentry.captureException(err);
      setError("Something went wrong");
    } finally {
      setIsSearching(false);
      setIsUpdatingUserList(false);
    }
  }

  return (
    <Page title="Resellers" secondaryActions={actions}>
      {users.length || isFiltered ? (
        <Layout>
          <Helmet>
            <title>Reseller list | Reserved area</title>
          </Helmet>
          <Layout.Section>
            <Card>
              <AssetsResourceList
                items={users}
                type="user"
                loading={isUpdatingUserList}
                getItemShortCutActions={itemShortCutActions}
                onClick={item => setSelectedUser(item)}
                searchable
                onSearch={handleSearch}
                isSearching={isSearching}
              />
            </Card>
            {!!nextToken && (
              <LoadMoreContainer>
                {isLoadingMore ? (
                  <Spinner />
                ) : (
                  <Button onClick={handleLoadMore}>Load more</Button>
                )}
              </LoadMoreContainer>
            )}
          </Layout.Section>
          {errorAction && (
            <Toast
              content={errorAction}
              error
              onDismiss={() => setErrorAction("")}
            />
          )}
        </Layout>
      ) : (
        <CustomClassContainer>
          <EmptyState
            heading="There are no resellers"
            action={{ content: "Home", url: "/dashboard" }}
            image={emptyCostumer}
          />
        </CustomClassContainer>
      )}
      {isModalCreateResellerOpen && (
        <ModalAddReseller
          isOpen={true}
          onClose={() => setIsModalCreateResellerOpen(false)}
          onCreateReseller={handleCreateReseller}
          error={error}
          onChange={() => setError(false)}
        />
      )}
    </Page>
  );
}
const CustomClassContainer = styled.div`
  .Polaris-EmptyState__Actions {
    display: none;
  }
`;
const LoadMoreContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;

export default ListUsers;
